.white_bg {
    background-color: #fff;
}

.about {
    width: 100%;
    .top_header {
        width: 100%;
        //background-image: url('../images/about_bg_mobile.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 500px;
        position: relative;
        z-index: 1;
        .desktop {
            display: none;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        .mobile {
            display: block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        @media (min-width: 1024px) {
            //background-image: url('../images/about_main.png');
            .mobile {
                display: none;
            }
            .desktop {
                display: block;
            }
        }
        .about_shadow {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, #222222 0%, #1B1B1B 0.01%, rgba(34, 34, 34, 0) 59.16%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        }
        .container {
            .title {
                width: 100%;
                padding-top: 126px;
                text-align: center;
                color: white;
                z-index: 1000;
                position: relative;
                @media (min-width: 1024px) {
                    padding-top: 252px;
                }
                h1 {
                    letter-spacing: -0.02em;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 700 !important;
                    @media (min-width: 1024px) {
                        font-size: 30px;
                        line-height: 46px;
                    }
                    span {
                        font-weight: 700;
                    }
                }
            }
            .logos {
                margin-top: 52px;
                padding-bottom: 104px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media (min-width: 1024px) {
                    flex-direction: row;
                    align-items: flex-start;
                    margin-top: 70px;
                    position: relative;
                    z-index: 1;
                }
                .first {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 1024px) {
                        width: fit-content;
                    }
                    .first_logo {
                        display: flex;
                        flex-direction: column;
                        .first_h1 {
                            margin-top: 5px;
                            line-height: 32px;
                            font-size: 16px;
                            font-weight: 700;
                            color: #fff;
                            text-align: center;
                            span {
                                font-weight: 400;
                                color: #578C76;
                            }
                            @media (max-width: 1024px) {
                                font-size: 13px;
                                line-height: 13px;
                            }
                        }
                        .first_top_logo {
                            width: 80px;
                            height: 80px;
                            background-color: #578C76;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @media (max-width: 1024px) {
                                width: 40px;
                                height: 40px;
                            }
                            .first_top_logo_h1 {
                                font-size: 40px;
                                font-weight: 900;
                                color: #fff;
                                font-family: $noto;
                                @media (max-width: 1024px) {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                    .plus {
                        margin: 0 10px;
                        margin-top: -19px;
                        @media (min-width: 1024px) {
                            margin: -34px 31px 0 31px;
                        }
                        p {
                            color: rgba(255, 255, 255, 0.6);
                            font-weight: 600;
                            font-size: 26px;
                            line-height: 36px;
                            @media (min-width: 1024px) {
                                font-size: 48px;
                                line-height: 50px;
                                font-weight: 600;
                            }
                        }
                    }
                    .second_logo_container {
                        width: fit-content;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        @media (max-width: 1024px) {
                            width: fit-content;
                            justify-content: center;
                        }
                        .second_h1 {
                            padding-left: 25px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 32px;
                            color: #fff;
                            span {
                                font-weight: 400;
		                        color: #f1b853;
                            }
                            @media (max-width: 1024px) {
                                font-size: 13px;
                                line-height: 13px;
                                padding: 0;
                                margin-top: 4px;
                            }
                        }
                        .second_logo {
                            width: 80px;
                            height: 80px;
                            background-color: #f1b853;
                            border-radius: 50%;
                            margin-right: 184px;
                            display: flex;
                            align-items: center;
                            @media (max-width: 1024px) {
                                width: 40px;
                                height: 40px;
                                margin-right: 90px;
                            }
                            .second_logo_h1 {
                                font-size: 40px;
                                font-weight: 700;
                                letter-spacing: 0.08em;
                                color: #fff;
                                padding-left: 35%;
                                line-height: 50px;
                                font-family: $motserrat;
                                @media (max-width: 1024px) {
                                    font-size: 20px;
                                    line-height: 50px;
                                }
                            }
                        }
                    }
                    .equal {
                        margin-top: -19px;
                        margin-left: 9px;
                        @media (min-width: 1024px) {
                            margin: -34px 30px 0 30px;
                        }
                        p {
                            color: rgba(255, 255, 255, 0.6);
                            font-weight: 600;
                            font-size: 26px;
                            line-height: 50px;
                            @media (min-width: 1024px) {
                                font-size: 48px;
                                line-height: 50px;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .second {
                    width: 100%;
                    margin-top: 36px;
                    display: flex;
                    justify-content: center;
                    @media (min-width: 1024px) {
                        width: 458px;
                        margin-top: 0;
                        align-items: flex-start;
                    }
                    .image_second {
                        max-width: 320px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media (min-width: 1024px) {
                            max-width: fit-content;
                        }
                        img {
                            width: 100%;
                            @media (min-width: 1024px) {
                                width: 458px;
                                margin-top: 8px;
                                margin-left: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
    .bot_header {
        background-color: #1B1B1B;
        clip-path: polygon(0 0, 100% 0, 100% 92%, 25% 100%, 0 97%);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 92%, 25% 100%, 0 97%);
        height: 747px;
        @media (min-width: 1024px) {
            height: 1184px;
        }
        .container {
            .image {
                width: 100%;
                margin-top: -10px;
                max-height: 400px;
                overflow: hidden;
                @media (min-width: 1024px) {
                    width: 100%;
                    padding-top: 263px;
                    max-height: none;
                }
                img {
                    width: 100%;
                    @media (min-width: 1024px) {
                        width: 100%;
                    }
                }
                .mobile {
                    display: block;
                    @media (min-width: 1024px) {
                        display: none;
                    }
                }
                .desktop {
                    display: none;
                    @media (min-width: 1024px) {
                        display: block;
                    }
                }
            }
            .title {
                padding-left: 20px;
                width: 100%;
                margin-top: -50px;
                @media (min-width: 1024px) {
                    margin-top: -100px;
                }
                h1 {
                    font-size: 22px;
                    line-height: 33px;
                    font-weight: 700;
                    color: white;
                    @media (min-width: 1024px) {
                        font-size: 46px;
                        line-height: 69px;
                    }
                    span {
                        line-height: 27px;
                        color: rgba(255, 255, 255, 0.6);
                        @media (min-width: 1024px) {
                            line-height: 56px;
                        }
                    }
                }
            }
            .des {
                width: 100%;
                padding-top: 30px;
                padding-bottom: 173.5px;
                @media (min-width: 1024px) {
                    padding-top: 38px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: fit-content;
                }
                .para {
                    margin-bottom: 42px;
                    padding-left: 20px;
                    width: 100%;
                    @media (min-width: 1024px) {
                        width: 58%;
                        margin-bottom: 0;
                    }
                    p {
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 400;
                        color: white;
                        @media (min-width: 1024px) {
                            font-size: 18px;
                            line-height: 32px;
                        }
                        span {
                            font-weight: 500;
                            color: rgba(53, 175, 139, 1);
                        }
                    }
                }
                .button {
                    cursor: pointer;
                    width: 100%;
                    background-color: rgba(53, 175, 139, 1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    height: 54px;
                    padding: 20px 36px;
                    gap: 14px;
                    color: white;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    font-family: "Noto Sans CJK kr Bold";
                    font-weight: 700;
                    @media (min-width: 1024px) {
                         font-size: 18px;
                         line-height: 26px;
                    }
                    @media (min-width: 1024px) {
                        width: 284px;
                        height: 66px;
                    }
                    img {
                        width: 17px;
                        color: white;
                    }
                }
            }
        }
    }
    .main_content {
        width: 100%;
        .container {
            .title {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $notocjkbold;
                margin-top: 55px;
                .image {
                    width: 180px;
                    position: relative;
                    @media (min-width: 1024px) {
                        width: 308px;
                    }
                    img {
                        width: 100%;
                    }
                    .round_01 {
                        background-color: rgba(219, 231, 238, 1);
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        position: absolute;
                        top: calc(50% - 6px);
                        left: 49px;
                        @media (min-width: 1024px) {
                            left: 90px;
                            top: calc(50% - 9px);
                            width: 18px;
                            height: 18px;
                        }
                    }
                    .round_02 {
                        background-color: rgba(219, 231, 238, 1);
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        position: absolute;
                        top: calc(50% - 6px);
                        right: 51px;
                        @media (min-width: 1024px) {
                            right: 91px;
                            top: calc(50% - 9px);
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                h1 {
                    font-size: 22px;
                    line-height: 33px;
                    font-weight: 700;
                    color: rgba(68, 68, 68, 1);
                    @media (min-width: 1024px) {
                        font-size: 46px;
                        line-height: 68px;
                    }
                }
            }
            .content {
                width: 100%;
                margin-top: 50px;
                @media (min-width: 1024px) {
                    margin-top: 80px;
                }
                .line {
                    width: 100%;
                    position: absolute;
                    border-bottom: 4px dotted rgba(240, 240, 240, 1);
                    left: 0;
                    margin-top: 50px;
                    z-index: 0;
                    @media (min-width: 1024px) {
                        border-bottom: 7px dotted rgba(240, 240, 240, 1);
                        margin-top: 60px;
                    }
                    .pacman {
                        position: absolute;
                        top: calc(50% - 8px);
                        left: 6px;
                        @media (min-width: 1024px) {
                            left: 81px;
                        }
                    }
                }
                .minions {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    position: relative;
                    z-index: 1;
                    row-gap: 5px;
                    @media (min-width: 1024px) {
                        row-gap: 0;
                    }
                    .box {
                        &:first-child {
                            width: 143px;
                        }
                        &:nth-child(2) {
                            width: 155px;
                        }
                        &:last-child {
                            width: 155px;
                        }
                        @media (min-width: 1024px) {
                            &:first-child {
                                width: 296px;
                            }
                            &:nth-child(2) {
                                width: 317px;
                            }
                            &:last-child {
                                width: 236px;
                            }
                        }
                        .image {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .title {
                            margin-top: 15px;
                            width: 100%;
                            text-align: center;
                            font-family: $motserrat;
                            @media (min-width: 1024px) {
                                margin-top: 8px;
                            }
                            .green {
                                color: rgba(87, 140, 118, 1);
                            }
                            .light_green {
                                color: rgba(53, 175, 139, 1);
                            }
                            .yellow {
                                color: rgba(241, 184, 83, 1);
                            }
                            h1 {
                                font-size: 20px;
                                line-height: 36px;
                                font-weight: 700;
                                @media (min-width: 1024px) {
                                    font-size: 32px;
                                    line-height: 50px;
                                }
                            }
                        }
                        .title2 {
                            width: 100%;
                            text-align: center;
                            margin: 4px 0;
                            @media (min-width: 1024px) {
                                margin: 10px 0 ;
                            }
                            h1 {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 32px;
                                color: rgba(34, 34, 34, 1);
                                @media (min-width: 1024px) {
                                    font-size: 22px;
                                    line-height: 32px;
                                }
                            }
                        }
                        .detail {
                            width: 100%;
                            text-align: center;
                            p {
                                font-size: 13px;
                                line-height: 22px;
                                font-weight: 400;
                                font-family: $noto;
                                color: rgba(68, 68, 68, 1);
                                @media (min-width: 1024px) {
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .end_content {
            margin-top: 120px;
            width: 100%;
            @media (min-width: 1024px) {
                margin-top: 223px;
            }
            .container {
                .end_content_wrapper {
                    display: flex;
                    flex-direction: column;
                    @media (min-width: 1024px) {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .detail {
                        width: 100%;
                        @media (min-width: 1024px) {
                            width: 30%;
                        }
                        h1 {
                            font-size: 22px;
                            line-height: 33px;
                            color: #303030;
                            font-family: "Noto Sans CJK kr Bold";
                            font-weight: 700;
                            margin-bottom: 20px;
                            @media (min-width: 1024px) {
                                font-size: 46px;
                                font-weight: bold;
                                line-height: 68px;
                            }
                        }
                        p {
                            font-size: 16px;
                            line-height: 28px;
                            font-weight: 400;
                            font-family: $noto;
                            color: #444;
                            @media (min-width: 1024px) {
                                font-size: 18px;
                                line-height: 32px;
                                font-weight: 500;
                            }
                        }
                    }
                    .big_box {
                        position: relative;
                        z-index: 1;
                        width: 320px;
                        height: 320px;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 44px auto 0 auto;
                        @media (min-width: 1024px) {
                            width: 640px;
                            height: 640px;
                            margin: 0;
                        }
                        .small_box {
                            width: 160px;
                            height: 160px;
                            @media (min-width: 1024px) {
                                width: 320px;
                                height: 320px;
                            }
                            .small_box_title {
                                margin: 16px 0 30px 16px;
                                @media (min-width: 1024px) {
                                    margin: 36px 0 114px 36px;
                                }
                                p {
                                    font-weight: 700;
                                    font-size: 13px;
                                    line-height: 22px;
                                    color: #FFFFFF;
                                    font-weight: 700;
                                    font-family: $noto;
                                    @media (min-width: 1024px) {
                                        font-size: 18px;
                                        line-height: 30px;
                                    }
                                }
                            }
                            .small_box_content {
                                width: 100%;
                                text-align: center;
                                p {
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #FFFFFF;
                                    @media (min-width: 1024px) {
                                        font-size: 30px;
                                        line-height: 30px;

                                    }
                                }
                            }
                        }
                        .about_box_bg_01 {
                            //background-image: url("../images/about_box_bg01.png");
                            background-position: center;
                            background-size: cover;
                        }
                        .about_box_bg_02 {
                            //background-image: url("../images/about_box_bg02.png");
                            background-position: center;
                            background-size: cover;
                        }
                        .about_box_bg_03 {
                            //background-image: url('../images/about_box_bg03.png');
                            background-position: center;
                            background-size: cover;
                        }
                        .about_box_bg_04 {
                            //background-image: url('../images/about_box_bg04.png');
                            background-position: center;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
    .main_footer {
        width: 100%;
        background-color: rgba(246, 246, 246, 1);
        clip-path: polygon(0 20%, 80% 0%, 100% 10%, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 20%, 80% 0%, 100% 10%, 100% 100%, 0 100%);
		margin-top: -180px;
        position: relative;
        z-index: 0;
        @media (min-width: 1024px) {
            margin-top: -320px;
        }
        .container {
            .main_footer_title {
                padding-top: 281px;
                width: 100%;
                padding-bottom: 140px;
                @media (min-width: 1024px) {
                    padding-top: 472px;
                }
                h1 {
                    font-size: 22px;
                    line-height: 33px;
                    font-family: "Noto Sans CJK kr Bold";
                    font-weight: 700;
                    color: #303030;
                    margin-bottom: 20px;
                    @media (min-width: 1024px) {
                        font-size: 46px;
                        line-height: 69px;
                        margin-bottom: 26px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #444;
                    @media (min-width: 1024px) {
                        font-size: 18px;
                        line-height: 32px;
                        font-weight: 500;
                    }
                }
            }
            .main_footer_content {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 226px;
                gap: 10px;
                row-gap: 60px;
                justify-content: flex-start;
                @media (min-width: 1024px) {
                    gap: 25px;
                    row-gap: 110px;
                }
                .logo {
                    width: 100px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1024px) {
                        width: 190px;
                        height: 56px;
                    }
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
