.hero_v {
    width: 100%;
    height: 320px;
    //background-image: url('../images/hero-bg.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .6);
    }

    .container.bla {
        height: 100%;
    }

    &__content {
        width: 100%;
        height: 100%;
        @include flexItems(column , center, flex-start , 1.5rem);
        &__title {
            width: 100%;
            color: white;
            margin-top: 3rem;
            h1.hv-title {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                font-family: $notocjk;
                span.pp {
                    font-weight: 600;
                }
                &.big {
                    font-size: 32px !important;
                    font-weight: 700;
                    font-family: $noto;
                    & ~ p {
                        font-size: 1rem;
                        line-height: 26px;
                        letter-spacing: -0.02em;
                        margin-top: 20px;
                    }

                    &--eng {
                        font-family: $motserrat;
                    }

                    @media (min-width: 800px) {
                        font-size: 48px !important;
                        & ~ p {
                            font-size: 24px;
                            line-height: 38px;
                        }
                    }
                }
            }
        }

        .logos {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            @media (min-width: 1024px) {
                flex-direction: row;
                align-items: center;
                margin-top: 128px;
                position: relative;
                z-index: 1;
            }
            .first {
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: 1024px) {
                    width: fit-content;
                }
                .first_logo {
                    display: flex;
                    flex-direction: column;
                    .first_h1 {
                        margin-top: 6px;
                        line-height: 32px;
                        font-size: 18px;
                        font-weight: 700;
                        color: #fff;
                        text-align: center;
                        width: 59px;
                        font-family: $noto;
                        span {
                            font-weight: 400;
                            color: #578C76;
                        }
                        @media (max-width: 1024px) {
                            width: 31px;
                            font-size: 10px;
                            line-height: 13px;
                        }
                    }
                    .first_top_logo {
                        width: 52px;
                        height: 52px;
                        background-color: #578C76;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media (max-width: 1024px) {
                            width: 23px;
                            height: 23px;
                        }
                        .first_top_logo_h1 {
                            font-size: 32px;
                            font-weight: 900;
                            line-height: 52px;
                            color: #fff;
                            font-family: $noto;
                            @media (max-width: 1024px) {
                                font-size: 14px;
                                line-height: 16px;
                                margin-top: -3px;
                            }
                        }
                    }
                }
                .plus {
                    margin: 0 16px 0px 8px;
                    margin-top: -19px;
                    @media (min-width: 1024px) {
                        margin: -38px 20px 0 13px;
                    }
                    p {
                        color: rgba(255, 255, 255, 0.6);
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 20px;
                        font-family: $motserrat;
                        @media (min-width: 1024px) {
                            font-size: 48px;
                            line-height: 50px;
                            font-weight: 600;
                        }
                    }
                }
                .second_logo_container {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    @media (max-width: 1024px) {
                        justify-content: center;
                    }
                    .second_h1 {
                        padding-left: 25px;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 32px;
                        color: #fff;
                        margin-top: 7px;
                        span {
                            font-weight: 400;
                            color: #f1b853;
                        }
                        @media (max-width: 1024px) {
                            font-size: 10px;
                            line-height: 13px;
                            padding: 0;
                            margin-top: 6px;
                        }
                    }
                    .second_logo {
                        width: 52px;
                        height: 52px;
                        background-color: #f1b853;
                        border-radius: 50%;
                        // margin-right: 300%;
                        margin-right: 155px;
                        display: flex;
                        align-items: center;
                        @media (max-width: 1024px) {
                            width: 23px;
                            height: 23px;
                            margin-right: 69px;
                        }
                        .second_logo_h1 {
                            font-size: 32px;
                            font-weight: 700;
                            letter-spacing: 0.08em;
                            color: #fff;
                            padding-left: 35%;
                            line-height: 50px;
                            font-family: $motserrat;
                            @media (max-width: 1024px) {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
                .equal {
                    margin: -19px 20px 0px 18px ;
                    @media (min-width: 1024px) {
                        margin: -38px 20px 0 20px;
                    }
                    p {
                        color: rgba(255, 255, 255, 0.6);
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 50px;
                        font-family: $noto;
                        @media (min-width: 1024px) {
                            font-size: 48px;
                            line-height: 50px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .second {
                width: 112px;
                height: 86px;
                display: flex;
                justify-content: center;
                @media (min-width: 1024px) {
                    // width: 458px;
                    width: 203px;
                    height: 155px;
                    margin-top: 0;
                    align-items: flex-start;
                }
                .image_second {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: 1024px) {
                        max-width: fit-content;
                    }
                    img {
                        width: 100%;
                        @media (min-width: 1024px) {
                            // width: 458px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        @media (min-width: 600px) {
            flex-direction: row;
            align-items: center;
            &__title {
                margin: 0;
            }
        }
    }
    @media (min-width: 800px) {
        height: 500px;
        &__content {
            &__title {
                margin-bottom: 3rem;
                h1.hv-title {
                    font-size: 36px;
                    line-height: 54px;
                    font-weight: 400;
                    letter-spacing: -0.02em;
                    span {
                        font-family: "Noto Sans CJK kr Bold";
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
