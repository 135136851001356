// colors
// ------------------------
$green: #35AF8B;
$yellow: #FFC67B;
$bg-yellow: #FFDA56;
$bg-green: #33F4BB;
$template-bg: #F7F7F7;
$hex-bg: #43D1A7;
$big-title: #222;
$sub-title: #333;
$text: #444444;
$grey-text: #808080;
$greyish: #666;
$light-green: #50E2B7;
$footer-bg: #303030;
$light-grey: #A5A5A5;
$whitish: #CFCFCF;
$drop: #DADADA;
$light-grey-text: #C4C4C4;

// ----------------------------
// font-family
$noto: 'Noto Sans KR', sans-serif;
$notocjkbold: 'Noto Sans CJK kr Bold', sans-serif;
$notocjkmedium: 'Noto Sans CJK kr Medium', sans-serif;
$notocjk: 'Noto Sans CJK kr', sans-serif;
$motserrat: 'Montserrat', sans-serif;
$poppins: 'Poppins', sans-serif;
