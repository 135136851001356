.mn {
    width: 100%;
    height: 100vh;
    background-color: white;
}
.loader {
    width: 100%;
    height: 100%;
    @include flexItems(row , center , center , 1rem);
    .hex-spinner {
        position: relative;
        width: 70px;
        height: 60px;
        overflow: hidden;
        clip-path: polygon(0 50% , 25% 0 , 75% 0 , 100% 50% , 75% 100% , 25% 100%);
        -webkit-clip-path: polygon(0 50% , 25% 0 , 75% 0 , 100% 50% , 75% 100% , 25% 100%);

        // animation: scale 1.5s linear infinite;
        &:nth-child(odd){
            background-color: $green;
        }
        &:nth-child(even){
            background-color: $yellow;
        }

        &:nth-child(3){
            animation: bla 1.5s ease-in-out infinite;
            z-index: 5;
        }

        &:nth-child(1){
            animation: bla1 1.5s ease-in-out infinite;
            z-index: 5;
        }

        &:nth-child(2){
            animation: bla2 1.5s ease-in-out infinite;
            z-index: 5;
        }
    }
}

// @keyframes scale {
//     0% {transform: scale(0);}
//     50% {transform: scale(1);}
//     100% {transform: scale(0);}
// }

@keyframes bla {
    0% {
        transform: translateX(0);
        //filter: blur(0);
        background-color: $green;
    }
    50% {
        transform: translateX(-172px);
        background-color: $yellow;
    }
    100% {
        transform: translateX(0);
        background-color: $green;
    }
}

@keyframes bla1 {
    0% {
        transform: translateX(0);
        background-color: $green;
    }
    50% {
        transform: translateX(172px);
        background-color: $yellow;
    }
    100% {
        transform: translateX(0);
        background-color: $green;
    }
}

@keyframes bla2 {
    0% {
        background-color: $yellow;
    }
    50% {
        background-color: $green;
        filter: blur(15px);
    }
    100% {
        background-color: $yellow;
    }
}
