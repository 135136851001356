@import '../filter';
@import '../pagination';
.main {
    width: 100%;
    background-color: white;
}

.programm {
    width: 100%;
    padding-top: 1rem;
    &__items {
        padding: 3rem 0;
        .item {
            padding-bottom: 40px;
            &--completed {
                .thumbnail {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 3;
                        background-color: rgba($color: #000000, $alpha: .6);
                    }
                    img {
                        filter: grayscale(100%);
                    }
                    .mark {
                        background-color: #FFDA56 !important;
                    }
                }
            }
            .thumbnail {
                width: 100%;
                height: 240px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .mark {
                    width: 100px;
                    text-align: center;
                    padding: 1rem;
                    padding-bottom: 2rem;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #33F4BB;
                    font-family: $notocjk;
                    clip-path: polygon(0 0 , 100% 0 , 100% calc(100% - 1.5rem) , 50% 100% , 0 calc(100% - 1.5rem));
                    font-weight: 700;
                    font-size: 13px;
                    z-index: 4;
                    color: rgba($color: #000000, $alpha: .5);
                    @media (min-width: 1024px) {
                        font-size: 1rem;
                    }
                }
            }
            .title {
                margin-top: 2rem;
                margin-bottom: .75rem;
                font-family: $motserrat;
                h1 {
                    color: #000;
                    font-weight: 500;
                    font-size: 1rem;
                    a {
                        color: inherit;
                        text-decoration: none;
                        font-family: $motserrat;
                    }
                }
            }
            .short {
                p {
                    font-weight: 500;
                    color: #444;
                    font-size: 13px;
                    font-family: $noto;
                }
            }
            .date {
                margin-top: 1.5rem;
                p {
                    color: #808080;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: $motserrat;
                }
            }
        }

        @media (min-width: 576px) {
            display: grid;
            grid-template-columns: repeat(2 , 1fr);
            // gap: 1.5rem;
            gap: 20px;
        }

        @media (min-width: 768px) {
            .item {
                .title {
                    h1 {
                        font-size: 18px;
                    }
                }
                .short {
                    p {
                        font-size: 15px;
                    }
                }
                .date {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        @media (min-width: 1024px) {
            grid-template-columns: repeat(3 , 1fr);
            .item {
                .thumbnail {
                    height: 308px;
                }
            }
        }
    }

    &__view {
        padding: 3rem 0;
        &__title {
            h1 {
                font-size: 20px;
                line-height: 32px;
                font-weight: 500;
                color: #000;
                font-family: $motserrat;
            }
            .date-short {
                margin: 1rem 0;
                padding-bottom: 1rem;
                @include flexItems(row , flex-start , center , 1rem);
                font-weight: 500;
                font-size: 13px;
                color: #444;
                font-family: $noto;
                .eng {
                    font-family: $motserrat;
                }
            }
        }
        &__content {
            width: 100%;
            border-top: 3px solid #35AF8B;
            padding: 1.5rem 0;
            .content-thumbnail {
                width: 100%;
                height: 220px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content-title {
                padding: 1.5rem 0;
                h1 {
                    font-weight: 500;
                    font-size: 1rem;
                    color: #000;
                    font-family: $motserrat;
                }
            }
            .content-table {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 4fr;
                gap: 2rem;
                .name {
                    @include flexItems(row , space-between , center , 0);
                    color: #000;
                    font-size: 13px;
                    font-weight: 500;
                    font-family: $noto;
                }
                .description {
                    font-size: 14px;
                    color: #444;
                    font-weight: 400;
                    font-family: $noto;
                    .eng {
                        font-family: $motserrat;
                    }
                }
            }

            .content-description {
                padding: 2rem 0;
                color: #444;
                font-size: 14px;
                line-height: 24px;
                font-family: $noto;
            }
            .content-btn {
                width: 100%;
                @include flexItems(row , center , center , .5rem);
                background-color: #35AF8B;
                padding: 1rem;
                color: white;
                font-weight: 700;
                font-size: 15px;
                font-family: $noto;
                cursor: pointer;
                a {
                    text-decoration: none;
                    color: white;
                }
            }
            @media (min-width: 768px) {
                display: flex;
                gap: 2.5rem;
                padding-top: 3rem;
                .content-thumbnail {
                    width: 50%;
                    height: 430px;
                }
                .more {
                    flex: 1;
                    align-self: stretch;
                    @include flexItems(column , space-between , flex-start , 0);
                    .dtl {
                        .content-title {
                            padding-top: 0;
                            h1 {
                                font-size: 20px;
                            }
                        }
                        .content-table {
                            padding-top: 1.5rem;
                            .name {
                                font-size: 15px;
                            }
                            .description {
                                font-size: 15px;
                            }
                        }
                        .content-description {
                            font-size: 1rem;
                        }
                    }
                }
                .content-btn {
                    text-decoration: none;
                    width: 250px;
                }
            }
        }

        &__pic {
            font-family: $noto;
            width: 100%;
            .sub-title {
                color: #444;
                font-size: 13px;
                padding-top: 1rem;
                padding-bottom: 1.5rem;
                width: 100%;
                border-bottom: 1px solid #e0e0e0;
            }

            .cover {
                width: 100%;
                height: 500px;
                margin: 2rem 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @media (min-width: 768px) {
                    margin-bottom: 4rem !important;
                    height: 720px;
                    margin-top: 4rem;
                }
            }

            .table {
                border-top: 1px solid #e0e0e0;
                &__item {
                    padding: 1rem 0;
                    @include flexItems(row , flex-start , center , 1rem);
                    border-bottom: 1px solid #e0e0e0;
                    font-family: $motserrat;
                    i {
                        font-size: 13px;
                        color: #aaa;
                    }
                    .dd {
                        font-size: 13px;
                        color: #aaa;
                        font-weight: 500;
                        white-space: nowrap;
                        font-family: $noto;
                        @media (min-width: 768px) {
                            font-size: 14px !important;
                        }
                    }
                    .trunc {
                        font-size: 13px;
                        color: #444;
                        text-overflow: ellipsis;overflow: hidden;
                        white-space: nowrap;
                        @media (min-width: 768px) {
                            font-size: 14px !important;
                        }
                    }
                }
            }

            .list-btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-top: 2rem;
                a {
                    text-align: center;
                    color: #828282;
                    padding: 1rem;
                    width: 116px;
                    background-color: #f2f2f2;
                    font-weight: 700;
                    font-size: 14px;
                    border: none;
                    @media (min-width: 768px) {
                        font-size: 16px !important;
                        margin-top: 2rem;
                        margin-bottom: 4rem !important;
                    }
                }
            }
        }
    }

}
