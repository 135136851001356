.intween {
    width: 90%;
    margin: auto;
    margin-top: .5rem;
    img {
        width: 100%;
    }

    @media (min-width: 500px) {
        margin-top: 5rem;
    }

    @media (min-width: 800px) {
        display: none;
    }
}
.service {
    width: 100%;
    height: 406px;
    background-color: white;
    margin-top: -1rem;
    position: relative;
    z-index: 1;
    clip-path: polygon(0 10% , 22% 0 , 100% 20% , 100% 100% , 0 100%);
    -webkit-clip-path: polygon(0 10% , 22% 0 , 100% 20% , 100% 100% , 0 100%);
    //background-image: url('../images/office2.jfif');
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    // background-image: url('/client/static/images/con3.png');
    // object-fit: fill;
    // background-size: auto 100%;
    // background-size: 400%;
    position: relative;
    .container {
        height: 100%;
    }

    &__layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background-color: rgba($color: #000000, $alpha: .4);
    }

    &__content {
        width: 100%;
        height: 100%;
        color: white;
        @include flexItems(column , center , flex-start , 3rem);
        position: relative;
        z-index: 10;
        h1 {
            text-transform: uppercase;
            font-family: $motserrat;
            font-size: 24px;
            margin-top: 3rem;
        }
    }

    &__description {
        h2 {
            color: $light-green;
            font-size: 18px;
            margin-bottom: 1.5rem;
            font-family: $noto;
            font-weight: 700;
            // width: 30%;
        }

        p {
            font-size: 16px;
            font-family: $noto;
            line-height: 32px;
            max-width: 430px;
            font-weight: 400;
            // margin-top: 22px;
            width: 70%;
            // background-color: yellow;
        }
        .more {
            font-size: 12px;
            font-weight: 600;
            font-family: $motserrat;
            margin-top: 3rem;
            display: flex;
            justify-content: end;
            align-items: flex-end;
            span{
                padding-left: 19px;
            }
            // position: absolute;
            // right: 0;
            // bottom: 0;
        }
    }

    @media (max-width: 470px) {
        &__description {
            p {
                font-size: 16px;
                line-height: 28px;
                width: 320px;
            }
        }
        .more{
            justify-content: start;
        }
    }
    @media (min-width: 800px) {
        // background-image: url('/client/static/images/home-service.png');
        background-position-y: 50%;
        height: 485px;
        clip-path: polygon(0 25% , 16% 0 , 100% 50% , 100% 100% , 0 100%);
        -webkit-clip-path: polygon(0 25% , 16% 0 , 100% 50% , 100% 100% , 0 100%);
        margin-top: 4rem;
        &__content {
            justify-content: space-around;
            gap: 0;
            h1 {
                font-size: 46px;
            }
        }

        &__description {
            width: 100%;
            @include flexItems(row , space-between , top , 2rem);
            h2 {
                margin: 0;
                font-size: 28px;
                width: 30%;
            }
            p {
                width: 44%;
                font-size: 18px;
                line-height: 32px;
            }
            .more {
                width: 25%;
                margin: 0;
                font-size: 14px;
                text-align: right;
            }
        }
    }
}

.mo {
    display: block;
}

.pc {
    display: none;
}

@media (min-width: 800px) {

    .pc {
         display: block;
    }
    .mo {
        display: none;
    }
}
