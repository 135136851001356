.main {
    width: 100%;
    background-color: white;
}

.it-service {
    width: 100%;
    padding: 7rem 0;
    @media (max-width: 1024px) {
        padding: 0;
    }

    &__items {
        padding: 50px 0;
        .item {
            padding-bottom: 40px;
            @include flexItems(column , flex-start , flex-start , 0);
            .thumbnail {
                width: 100%;
                height: 240px;
                position: relative;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .it-item {
                height: 100%;
                flex: 1;
                @include flexItems(column , flex-end , flex-start , 0);
            }

            .logo {
                padding-top: 40px;
                @media (max-width: 1024px) {
                    padding-top: 30px;
                }
            }

            .title {
                margin-top: 0.75rem;
                margin-bottom: 22px;
                @media (max-width: 1024px) {
                    margin-top: 8px;
                    margin-bottom: 12px;
                }
                h1 {
                    color: #222222;
                    font-weight: 500;
                    font-size: 20px;
                    a {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }
            .short {
                margin-bottom: 46px;
                p {
                    font-weight: 400;
                    color: #444;
                    font-size: 16px;
                    font-family: $noto;
                }
            }
            &__btn {
                width: 100%;
                button {
                    background-color: #35af8b;
                    color: white;
                    width: 260px;
                    padding: 1.25rem;
                    border: 1px solid #35af8b;
                    font-weight: bold;
                    font-family: $noto;
                    white-space: nowrap;
                    font-size: 16px;
                    @include flexItems(row, center, center, 0.5rem);
                    &.light {
                        background-color: white;
                        color: #35af8b;
                    }
                }
            }
        }

        @media (min-width: 576px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            .item {
                &__btn {
                    width: 60%;
                }
            }
        }

        @media (min-width: 768px) {
            .item {
                .title {
                    h1 {
                        font-size: 18px;
                    }
                }
                .short {
                    p {
                        font-size: 15px;
                    }
                }
                .date {
                    p {
                        font-size: 14px;
                    }
                }
                &__btn {
                    width: 60%;
                }
            }
        }
        @media (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
            .item {
                .thumbnail {
                    height: 308px;
                }
            }
        }
    }
}
