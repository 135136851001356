
.hero {
    width: 100%;
    height: 615px;
    //background-image: url(../images/hero-bg.jpeg);
    background-image: url('/client/static/images/main-m-bg.png');
    background-position: center;
    // background-position: 10%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @include flexItems(row, center, flex-end, 0);
    padding-bottom: 2px;
    &__layer {
        position: absolute;
        top: 0;
        left: 0;
        // background-color: rgba($color: #000000, $alpha: .75);
        width: 100%;
        height: 100%;
    }
    &__clip {
        position: absolute;
        height: 40%;
        width: 100%;
        background-color: $template-bg;
        bottom: 0;
        clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
        -webkit-clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
    }
    &__text {
        @include flexItems(column , center , flex-start , 0rem);
        font-family: $motserrat;
        color: white;
        font-size: 1rem;
        margin-bottom: 110px;
        position: relative;
        z-index: 5;
        img {
            margin-top: 16px !important;
        }
        h3 {
            // font-family: $motserrat;
            font-family: $noto;
            margin-top: 1rem;
            font-size: 22px;
            color: $yellow;
            span {
                color: white;
            }
        }
        .desktop {
            display: none
        }
    }
    &__top {
        @include flexItems(row , space-between , center , 0);
        margin: 1rem 0 1.5rem 0;
        font-family: $motserrat;
        color: white;
        font-weight: 600;
        position: relative;
        z-index: 5;
        h2 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 46px;
        }
        a {
            font-size: 14px;
            position: absolute;
            bottom: 0;
            right: 0;
            span{
                padding-left: 19px;
            }
        }
    }
    &__swiper {
        width: 100%;
        //height: 250px;
        //margin-bottom: -2px;
        .swiper {
            width: 100%;
            height: 100%;
            padding-bottom: 15px;
            margin-bottom: -15px;
            // overflow: visible;
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    padding-bottom: 24px;
                    &-active{
                        box-shadow: none;
                        z-index: 1;
                    }
                    &:hover{
                        z-index: 999;
                        box-shadow: 0px 0px 15px rgba(0,0,0, .1);
                    }
                    .content {
                        width: 100%;
                        height: 210px;
                        position: relative;
                        margin: 0 auto;
                        // padding: 1.5rem;
                        padding: 30px;
                        background-color: white;
                        padding-right: 85px;
                        @include flexItems(column , space-between , flex-start , 0);
                        .tag {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 70px;
                            padding-top: 20px;
                            text-align: center;
                            padding: .5rem 0;
                            background-color: $bg-green;
                            line-height: 24px;
                            .arrow {
                                position: absolute;
                                left: 0;
                                top: 100%;
                                @include arrow($bg-green)
                            }
                            &--yellow {
                                background-color: $bg-yellow;
                                .arrow {
                                    @include arrow($bg-yellow)
                                }
                            }
                            p {
                                font-size: 13px;
                                line-height: 24px;
                                font-family: $noto;
                                font-weight: 900;
                                position: absolute;
                                top: 30%;
                            }
                        }

                        h3 {
                            font-size: 16px;
                            line-height: 28px;
                            margin-bottom: .75rem;
                            color: #333333;
                        }
                        div p {
                            color: $text;
                            font-size: 14px;
                            font-family: $noto;
                        }

                        p {
                            color: $grey-text;
                            font-size: 13px;
                            font-weight: 500;
                            font-family: $motserrat;
                        }
                    }
                    @media (min-width: 800px) {
                        padding-bottom: 0;
                         h3{
                            font-size: 18px !important;
                        }
                    }
                }
            }
            .swiper-scrollbar {
                width: 90%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
                @media (min-width: 800px) {
                    display: none;
                }
            }
            @media (min-width:800px) {
                overflow: hidden;
            }
        }
    }
    @media (min-width: 800px) {
        height: 800px;
        &__clip {
            clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
        }

        &__text {
            margin-bottom: 8.8rem;
            p {
                font-size: 24px;
            }
            .mobile {
                display: none;
            }
            .desktop {
                display: block;
                width: 517px;
            }

            &__line {
                margin-top: 30px;
                h3 {
                    font-size: 36px;
                }
            }
        }
        &__top {
            h2 {
                font-size: 46px;
                padding-top: 2rem;
                margin-top: 1rem;
            }
        }
    }
    @media (min-width: 1030px) {
        &__text {
            &__line {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }
        }

    }
    @media (min-width: 1520px) {
        &__clip {
            clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 50%, 16.2% 99.4%, 100% 18%, 100% 100%, 0 100%);
        }
    }
    @media (max-width: 430px) {
        &__clip {
            clip-path: polygon(0 50%, 50% 100%, 100% 100%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 50%, 50% 100%, 100% 100%, 100% 100%, 0 100%);
        }
        &__swiper {
            margin-bottom: -54.11px;
        }
        &__top {
            h2 {
                font-size: 24px;
            }
        }
    }
    @media (max-width: 799px) {
        &__clip {
            clip-path: polygon(0 50%, 50% 100%, 100% 100%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 50%, 50% 100%, 100% 100%, 100% 100%, 0 100%);
        }
    }
}
// (0 50%, 18% 100%, 100% 20%, 100% 100%, 0 100%)
// (0 50%, 18% 100%, 100% 0, 100% 100%, 0 100%)
