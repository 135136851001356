.brands {
    width: 100%;
    height: auto;
    background-color: $big-title;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 5;
    &__container {
        width: 100%;
        [class~='brands__brand']:last-of-type  {
            width: 102%;
        }
    }
    &__brand {
        [class~='list']:last-of-type  {
            background: #000;
        }
        width: 100%;
        padding: 25px 0;
        align-self: stretch;
        font-family: $noto;
        .logo {
            height: 50px;
            @include flexItems(row, flex-start, flex-start, 0);
            margin-bottom: 15px;
            img {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        //@include flexItems(column, space-between, flex-start, 1rem);

        h2 {
            color: white;
            margin-top: 0;
            font-size: 16px;
            line-height: 28px;
        }

        .brand_title {
            p {
                margin-top: 5px;
                margin-bottom: 0.5rem;
                font-family: $motserrat;
                font-weight: 500;
                color: white;
                display: none;
            }
        }
        p {
            color: $light-grey-text;
            margin-top: 10px;
            font-size: 13px;
            line-height: 22px;
        }

        a {
            margin-top: 50px;
            color: white;
            text-decoration: none;
            //margin-top: 1.5rem;
            width: 124px;
            padding: 1rem;
            background-color: #35af8b;
            color: white;
            width: 160px;
            height: 50px;
            border: 1px solid #35af8b;
            @include flexItems(row, center, center, 0.5rem);
            .txt {
                color: white;
                font-weight: 700;
                font-size: 15px;
            }
        }

        .btn {
            font-size: 15px;
            @media (min-width: 1024px) {
                font-size: 18px;
            }
        }
    }
    @media (min-width: 500px) {
        height: auto;
        &__container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
        }

        &__brand {
            padding: 5.8rem 0;
            .logo {
                margin-bottom: 35px;
            }
            .brand_title {
                p {
                    display: block;
                }
            }
            h2 {
                font-size: 18px;
            }

            p {
                color: $light-grey-text;
                font-size: 15px;
                line-height: 26px;
                margin-top: 25px;
            }
        }
    }

    @media (min-width: 1024px) {
        &__container {
            grid-template-columns: repeat(4, 1fr);
            padding: 0 0;
        }
    }

    @media (min-width: 1200px) {
        &__container {
            gap: 4rem;
        }
    }
}
