.rocket {
    width: 100%;
    height: 439px;
    background-color: $bg-yellow;
    color: $big-title;
    position: relative;
    z-index: 5;
    &__container {
        padding: 4.3rem 0;
        @include flexItems(column , flex-start , flex-start , 1rem);
        p {
            font-size: 14px;
            font-family: $noto;
            font-weight: 400;
            margin-top: 10px;
            font-family: $noto;
            width: 100%;
            // width: 37%;
            span {
                font-family: $noto;
                font-weight: 500;
                color: #000;
                font-weight: 500;
            }
        }
        p{
            line-height: 28px;
        }
        .title {
            width: 100%;
            h1 {
                font-family: $noto;
                font-weight: 700;
                font-size: 24px;
                margin-bottom: 20px;
                font-family: $noto;
                font-weight: 700;
            }
            p {
                font-family: $noto;
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                color: #222222;
                font-weight: 500;
                width: 100%;
            }
        }
        .button {
            width: 100%;
            // width: 33%;
            margin-top: 2rem;
            button {
                width: 100%;
                padding: 1rem;
                text-align: center;
                background-color: $big-title;
                color: white;
                @include flexItems(row , center , center , .75rem);
                border: 1px solid $big-title;
                p {
                    margin: 0;
                    font-weight: 700;
                    font-family: $noto;
                    white-space: nowrap;
                    width: 120px;
                    font-size: 16px
                }
            }
        }

        @media (min-width: 800px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            gap: 4rem;
            .button {
                width: 33%;
                // width: 305px;
                button {
                    font-size: 18px;
                    max-width: 305px;
                }
            }

            p {
                font-size: 18px;
                width: 35%;
            }

            .title {
                width: 32%;
                h1 {
                    font-size: 46px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }
    @media (min-width: 800px) {
        height: auto;
        &__container {
            padding: 87.5px 0;
        }
    }
}
