.main {
    width: 100%;
    background-color: white;
}
.business {
    width: 100%;
    padding: 3rem 0;
    &__details {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        font-family: $noto;
    }

    &__card {
        height: 198px;
        border: 1px solid #e7e7e7;
        position: relative;
        @include flexItems(column , center , center , 0);
        .backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            @include flexItems(row , center , center , 0);
            h1 {
                font-family: $motserrat;
                font-size: 100px;
                font-weight: 800;
                color: #f8f8f8;
            }
        }

        .info {
            position: relative;
            z-index: 2;
            text-align: center;
            .type {
                font-weight: 500;
                font-size: 1rem;
                color: $big-title;
                margin-bottom: 5px;
            }

            .title {
                color: #578C76;
                font-size: 20px;
                margin-bottom: 1rem;
            }

            .description {
                color: #444;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .link {
            color: #444;
            font-size: 12px;
            font-weight: 500;
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: .5rem;
            text-decoration: underline;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            cursor: pointer;
            img {
                width: 12px;
            }
        }

    }

    &__btn {
        width: 100%;
        padding-top: 2rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 16px;
        button {
            background-color: #35AF8B;
            color: white;
            height: 60px;
            width: 100%;
            padding: 1.25rem;
            border: 1px solid #35AF8B;
            font-size: 16px !important;
            font-weight: 700;
            font-family: $noto;
            @include flexItems(row , center , center , .5rem);
            &.light {
                background-color: white;
                color: #35AF8B;
            }
        }
    }

    @media (min-width: 768px) {
        &__details {
            grid-template-columns: repeat(2 , 1fr);
        }

        &__card {
            .backdrop {
                h1 {
                    font-size: 140px;
                }
            }
        }

        &__btn {
            padding-top: 4rem !important;
            @include flexItems(row , center , center , 1rem);
            button {
                width: 300px;
            }
        }
    }

    @media (min-width: 1200px) {
        padding: 9.375rem 0;
        &__details {
            grid-template-columns: repeat(3 , 1fr);
        }

        &__card {
            height: 310px;
            .backdrop {
                h1 {
                    font-size: 180px;
                }
            }

            .info {
                .type {
                    font-size: 20px;
                }

                .title {
                    font-size: 28px;
                }

                .description {
                    font-size: 1rem;
                }
            }
            .link {
                bottom: 1.5rem;
                font-size: 13px;
            }
        }
    }
}
