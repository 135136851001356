@mixin flexItems($dir , $justify , $items , $gap) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify;
    align-items: $items;
    gap: $gap;
}

@mixin arrow($color) {
    border-top: 20px solid $color;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
}