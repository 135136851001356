@import './includes/variables';
@import './includes/reset';
@import './includes/mixin';
@import './includes/header';
@import './includes/hero';
@import './includes/loader';

@import './includes/hero_v';
@import './includes/ci-bi/main';

@import './includes/business/business';
@import './includes/programm/programm';
@import './includes/portfolio/portfolio';
@import './includes/contact/contact';
@import './includes/it-service/it-service';
@import './includes/voucher/voucher';
@import './includes/about';

@import './includes/hexSlider';
@import './includes/service';
@import './includes/brands';
@import './includes/rocket';
@import './includes/footer';
@import "https://unpkg.com/swiper@8/swiper-bundle.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";

@font-face {
    font-family: "Noto Sans CJK kr Bold";
    font-weight: 700;
    src: url(./fonts/NotoSansCJKkr-Bold.woff2) format("woff2");
}

@font-face {
    font-family: "Noto Sans CJK kr";
    src: url(./fonts/NotoSansCJKkr-Regular.woff2) format("woff2");
}
@font-face {
    font-family: "Noto Sans CJK kr Black";
    src: url(./fonts/NotoSansCJKkr-Black.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans CJK kr Medium";
    font-weight: 500;
    src: url(./fonts/NotoSansCJKkr-Medium.woff) format("woff");
}
