.footer {
    width: 100%;
    height: 419px;
    background-color: #303030;
    position: relative;
    z-index: 5;
    &__content {
        padding: 4.32rem 0;
        color: #a5a5a5;
        @include flexItems(column , space-between , flex-start , 2rem);
        @media (min-width: 800px) {
            flex-direction: row;
        }

        @media (min-width: 1200px) {
            gap: 6rem;
        }
    }

    &__nav {
        width: 100%;
        margin-left: 0px;
        .select {
            width: 100%;
            margin-bottom: 1rem;
            @include flexItems(row , space-between , center , 0);
            .row {
                @include flexItems(row , center , center , 1.5rem);
                h1 {
                    font-size: 13px;
                    color: #CFCFCF;
                }
                a:last-child {
                    margin-top: 5px;
                }
                a {
                    color: #a5a5a5;
                    text-decoration: none;
                }

                &:nth-child(2){
                    @media (min-width: 800px) {
                        display: none;
                    }
                }
            }
        }

        .info {
            font-family: $poppins;
            max-width: 300px;
            .address {
                font-size: 13px;
                line-height: 22px;
                // font-family: $noto;
            }
            .copyright {
                font-size: 13px;
                margin-top: 1rem;
            }
        }

    }
    .options_container {
        width: 100%;
        .row {
            width: 100%;
            display: none !important;
            @include flexItems(row , flex-end , center , 1rem);
            margin-bottom: 1rem;
            a:last-child {
                margin-top: 5px;
            }
            a {
                color: #a5a5a5;
                text-decoration: none;
            }
        }
        .options {
            width: 100%;
            @include flexItems(row , space-between , center , 0);

            padding: 1rem 1.5rem;
            background-color: #3a3a3a;
            p {
                font-family: 'Poppins', sans-serif;
                color: #DADADA;
                letter-spacing: 0.03em;
                font-size: 13px;
                font-weight: 500;
            }
            i::before{
                position: relative;
                top: -4px;
            }
        }

        @media (min-width: 800px) {
            width: 300px;
            .row {
                display: flex !important;
            }
            .options{
                width: 220px;
            }
        }
    }

    @media (min-width: 800px) {
        height: auto;
        &__nav {
            .info {
                max-width: 100%;
                // margin-left: 50px;
            }
        }
        &__content {
            padding: 3.5rem 0;
        }
    }

}
